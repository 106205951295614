import React from "react"

export default function Section4() {
  return (
    <div id="why" className="auto-section">
      <div>
        <img
          src={require("../../img/sysdone-ani-icon.gif")}
          className="ani-logo"
          alt="ani-logo"
        />
        <h1 className="title-blue">Automation is your answer!</h1>
        <p className="p-blue">
          Automate your business workflow. Integrate your various systems so
          that they work in unison. Get live data across your different
          platforms. And so much more.
        </p>
      </div>
      <div className="computer-div">
        <img
          src={require("../../img/laptop-basic.59972b4c.png")}
          className="computer"
          alt="computer"
          data-sal="slide-down"
          data-sal-delay="200"
          data-sal-easing="ease"
          data-sal-duration="1200"
        />
      </div>
    </div>
  )
}
